import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

import * as React from 'react';
import * as styles from './index.module.css';

import { A11y, Autoplay, Navigation, Scrollbar } from 'swiper';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Heading,
	Link,
	Stack,
	Text,
	VStack,
} from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import useAuth, { GET_USER } from '../components/useAuth';

import { FunctionComponent } from 'react';
import HeroVideoSection from '../components/hero-video-section';
import Layout from '../components/Base/Layout';
import PopUp from '../components/PopUp';
import ProductsSlider from '../components/products-slider';
import PromotionCard from '../components/PromotionCard';
import ReviewCard from '../components/review-card';
import { SEO } from '../components/Base/Seo';
import { SingleProductCard } from '../components/Product/SingleProductCard';
import { TOP_SALES } from '../queries/fetchTopSales';
import hpFaq from '../constants/hpFaq';
import { navigate } from 'gatsby';
import { newInStock } from '../queries/newInStock';
import promotionCards from '../constants/promotionCards';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';

const HomePage: FunctionComponent = () => {
	const swiperChild = {
		display: `flex`,
		width: `246px`,
		maxWidth: `246px`,
		marginRight: `0`,
	};

	const reviewChild = {
		display: `flex`,
		width: `100px`,
		paddingBottom: '10px',
	};

	let productsQuery;
	const { data: userData } = useQuery(GET_USER);
	const { loggedIn, isDefaultCustomer, isUnlicensedUser } = useAuth();

	const newProducts = newInStock();

	const filteredProducts = newProducts.filter((item: any) => {
		if (
			item.productTags?.nodes?.some(
				(tag: any) => tag.name === 'Pharmaceuticals'
			) &&
			(!loggedIn || isUnlicensedUser || isDefaultCustomer)
		) {
			return false;
		}
		return true;
	});

	// const popUpAlreadyShown =
	//   typeof window !== "undefined" &&
	//   sessionStorage.getItem("popUpAlreadyShown");
	// const [showPopUp, setShowPopUp] = React.useState(false);

	// React.useEffect(() => {
	//   if (!popUpAlreadyShown && loggedIn) {
	//     setTimeout(() => setShowPopUp(true), 2000);
	//     sessionStorage.setItem("popUpAlreadyShown", "true");
	//   }
	// }, [popUpAlreadyShown, loggedIn]);

	return (
		<>
			<Layout>
				<div className={styles.homePage}>
					<HeroVideoSection />
					{/* <div style={{ zIndex: "999", position: "absolute" }}>
						<PopUp isOpen={showPopUp} setIsOpen={setShowPopUp} />
					</div> */}
					
					<Box w="100%" id="anchorlink" className={styles.homePage}><ProductsSlider /></Box>
					<div className={styles.refferralsection}>
						{promotionCards.map((item) => (
							<PromotionCard
								promotionTitle={item.title}
								promotionText={item.description}
								ctaText={item.buttonText}
								link={item.link}
							/>
						))}
					</div>
					{/* best selling products */}
					<div className={styles.topsalessection}>
					<div className={styles.sectioncontent}>
							<div className={styles.salestextbox}>
								<div className={styles.sectiontitle}>
									<div className={styles.divider} />
									<div className={styles.title}>
										<Text
											as='h2'
											className={styles.topSales}>
											New In Stock
										</Text>
									</div>
								</div>
								<div className={styles.saleproducts}>
									<div className={styles.swiperWidth}>
										<Swiper
											modules={[Navigation, Scrollbar, A11y]}
											breakpoints={{
												320: { slidesPerView: 1 },
												375: { slidesPerView: 1 },
												425: { slidesPerView: 1 },
												576: { slidesPerView: 2 },
												768: { slidesPerView: 3 },
												1024: { slidesPerView: 4 },
												1440: { slidesPerView: 5 },
												1920: { slidesPerView: 5 },
											}}
											style={{ overflow: 'visible', width: '100%' }}
											slidesPerView={5}
											spaceBetween={0}
											navigation
											pagination={{ clickable: true }}
											className={styles.mySwiper}
											scrollbar={{ hide: false, draggable: true }}>
											<div className={styles.productfeed}>
												<div className={styles.saleproducts}>
													{filteredProducts.map((product: any) => (
														<SwiperSlide
															key={product.id}
															style={swiperChild}>
															<SingleProductCard
																props={product}
																qtyInputOn={false}
																outlet={false}
																margin={'0 0.3rem 1.25rem 0.3rem'}
																width={'100%'}
															/>
														</SwiperSlide>
													))}
												</div>
											</div>
										</Swiper>
									</div>
								</div>
							</div>
						</div>
						<Stack
							w='100%'
							display='flex'
							alignItems='center'>
							<Link
								href='/shop'
								_hover={{ textDecoration: 'none' }}>
								<Button
									border='0.4px solid #120b0c'
									borderRadius={'48px'}
									py='0.75rem'
									px='1.5rem'
									mt={16}
									bg='transparent'
									textTransform='uppercase'
									lineHeight='117%'
									fontFamily={'Montserrat'}
									_hover={{ bg: '#120b0c', color: 'white' }}>
									View All Products
								</Button>
							</Link>
						</Stack>
						</div>
					<div className={styles.reviews}>
						<div className={styles.ratingssection}>
							<div className={styles.titlebox}>
								<div className={styles.divider} />
								<div className={styles.title1}>
									<Text
										as='h2'
										className={styles.whatOurCustomers}>
										What our customers say
									</Text>
								</div>
							</div>
						</div>
					</div>

					<div
						style={{
							width: '100%',
							backgroundColor: '#f9f9f9',
							paddingBottom: 40,
						}}>
						<Swiper
							modules={[Navigation, Scrollbar, Autoplay]}
							breakpoints={{
								320: { slidesPerView: 1 },
								664: { slidesPerView: 2 },
								1440: { slidesPerView: 2 },
								1920: { slidesPerView: 3, spaceBetween: 60 },
							}}
							slidesPerView={2}
							slidesPerGroup={1}
							spaceBetween={20}
							autoplay={{
								delay: 3000,
								pauseOnMouseEnter: true,
							}}
							loop={true}
							draggable={false}
							allowTouchMove={false}
							style={{ paddingLeft: 20, paddingRight: 10 }}>
							<SwiperSlide style={reviewChild}>
								<ReviewCard
									starIcon1='/star-3.svg'
									starIcon2='/star-4.svg'
									starIcon3='/star-5.svg'
									ratingTitle='Best Prices on the Market'
									ratingText='Customer service is excellent! Doctor Medica’s prices really cannot be beat. They have a great selection of products. And they have fast shipping. I have been using them for more than 10 years, and I would definitely recommend them to my colleagues.'
									ratingName='TY'
								/>
							</SwiperSlide>

							<SwiperSlide style={reviewChild}>
								<ReviewCard
									starIcon1='/star-3.svg'
									starIcon2='/star-4.svg'
									starIcon3='/star-51.svg'
									ratingTitle='Prompt and Professional'
									ratingText='They are always prompt in getting our orders out and check in with us periodically to see if we need anything. Very professional as well.'
									ratingName='Karen T'
								/>
							</SwiperSlide>

							<SwiperSlide style={reviewChild}>
								<ReviewCard
									starIcon1='/star-32.svg'
									starIcon2='/star-42.svg'
									starIcon3='/star-51.svg'
									ratingTitle='Exceptional Customer Service'
									ratingText='Exceptional customer service, great pricing, and fast shipping! Any minor issues are corrected immediately, and communication is outstanding. Would highly recommend! I’ve been using them for years and will continue to give them my business!'
									ratingName='Jamie B'
								/>
							</SwiperSlide>

							<SwiperSlide style={reviewChild}>
								<ReviewCard
									starIcon1='/star-32.svg'
									starIcon2='/star-42.svg'
									starIcon3='/star-51.svg'
									ratingTitle='Timely Orders'
									ratingText='My orders have been filled timely and received as promised. Products are always available, and the staff is easy to work with should you need to speak with anyone. I would recommend them to everyone.'
									ratingName='Candice D'
								/>
							</SwiperSlide>
						</Swiper>
					</div>
					<div className={styles.categories}>
						<div className={styles.ratingssection1}>
							<div className={styles.ratingssection1}>
								<div className={styles.divider} />
								<div className={styles.title2}>
									<Text
										as='h2'
										className={styles.topCategories}>
										Top Categories
									</Text>
								</div>
							</div>
							<div className={styles.ratings1}>
								<Link
									className={styles.categorycard}
									href='/category/dermal-filler'
									_hover={{ color: 'white!important' }}>
									<img
										className={styles.backdropIcon}
										alt='dm-dermalfillers'
										src='/dm-dermalfillers-thumb.webp'
									/>
									<div className={styles.content}>
										<Text
											as='h2'
											fontWeight={700}
											className={styles.skincare}>
											Fillers
										</Text>
									</div>
								</Link>
								<Link
									className={styles.categorycard}
									href='/category/orthopaedic'
									_hover={{ color: 'white!important' }}>
									<img
										className={styles.backdropIcon}
										alt='dm-skincare'
										src='https://shop.doctormedica.co/wp-content/uploads/2024/02/categoryCard-ortho-image.webp'
									/>
									<div className={styles.content}>
										<Text
											as='h2'
											fontWeight={700}
											className={styles.skincare}>
											Orthopaedic
										</Text>
									</div>
								</Link>

								<Link
									className={styles.categorycard}
									href='/category/mesotherapy'
									_hover={{ color: 'white!important' }}>
									<img
										className={styles.backdropIcon}
										alt='dm-fatremoval'
										src='/dm-fatremoval-thumb.webp'
									/>
									<div className={styles.content}>
										<Text
											as='h2'
											fontWeight={700}
											className={styles.skincare}>
											Mesotherapy
										</Text>
									</div>
								</Link>
							</div>
						</div>
					</div>
					<Box
						py={{ base: 10, md: 20 }}
						px={{ base: 9, md: 10 }}
						w={{ base: '100%', md: '1280px' }}
						mx={{ base: 0, md: 'auto' }}>
						<VStack alignItems='flex-start'>
							<Box
								bg='#BD2A29'
								width='24px'
								height='2px'></Box>
							<Heading
								pt={6}
								pb={10}
								fontSize={{ base: '30px !important', md: '36px !important' }}
								fontWeight={400}>
								Frequently asked questions
							</Heading>
						</VStack>
						<Accordion
							allowToggle
							border='none'
							w={{ base: '100%', md: '100%' }}
							defaultIndex={[0]}>
							{hpFaq.map((item, index) => {
								return (
									<AccordionItem
										key={index}
										border='none'
										borderBottom='1px solid #E1E7EF'
										borderTop={index === 0 ? '1px solid #E1E7EF' : 'none'}>
										<AccordionButton
											textAlign='left'
											border='none'
											py={4}
											px={{ base: 0, md: 4 }}
											justifyContent={'space-between'}
											_hover={{ background: 'transparent' }}>
											<Text
												color='#1A212D'
												fontSize='md'
												fontWeight={600}
												lineHeight='24px'>
												{item.question}
											</Text>
											<AccordionIcon
												width='30px'
												height='30px'
												color='#DDA572'
											/>
										</AccordionButton>
										<AccordionPanel px={{ base: 0, md: 4 }}>
											<Text
												color='#595959'
												fontSize='sm'
												fontWeight={400}
												lineHeight='24px'>
												{item.answer}
											</Text>
										</AccordionPanel>
									</AccordionItem>
								);
							})}
						</Accordion>
					</Box>
				</div>
			</Layout>
		</>
	);
};

export const Head = () => (
	<SEO
		title='Need Help Buying BOTOX® Online? | DoctorMedica'
		description='Reach DoctorMedica and let us help you find the best Botox suppliers in the US and around the globe. Genuine products only. Price match guaranteed.'
	/>
);

export default HomePage;
